<template>
    <div class="Projectcreation">
        <div class="card-box">
            <el-row :gutter="25">
                <el-col 
                    :xs="16" 
                    :sm="20"
                    :md="8" 
                    :lg="8" 
                >
                    <span>
                          • 
                    </span>
                    <span class="header-title">
                        {{form.name}}
                    </span> 
                </el-col>
                <el-col 
                    :xs="0" 
                    :sm="0"
                    :md="5" 
                    :lg="4" 
                >
                    <span>课堂码</span> • <span>{{form.code}}</span>
                </el-col>
                <el-col 
                    :xs="0" 
                    :sm="0"
                    :md="5" 
                    :lg="4" 
                >
                    <span>我的小组</span> • <span>{{form.team}}</span>
                </el-col>
                <el-col 
                    :xs="0" 
                    :sm="0"
                    :md="4" 
                    :lg="3" 
                >
                    <span>小组人数</span> • <span>{{form.users}}人</span>
                </el-col>
                <el-col 
                    :xs="8" 
                    :sm="4"
                    :md="2" 
                    :lg="5" 
                >
                    <div class="retu">
                        <el-tooltip content="返回上一层" placement="top-start">
                            <i class="iconfont icon-ffanhui-" @click="returnTo()"></i>
                        </el-tooltip>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="content_box">
            <ul class="tab_box clearfix">
                <li class="fl" :class="istrue==1?'active':''" @click="istrues(1)">
                    <span>我创建的项目</span>
                </li>
                <li class="fl" :class="istrue==2?'active':''" @click="istrues(2)">
                    <span>我参与的项目</span>
                </li>
            </ul>
            <div class="tab_content">
                <el-row :gutter="25">
                    <el-col 
                        v-show="istrue==1"
                        :xs="24" 
                        :md="12" 
                        :lg="8" 
                        :xl="6">
                            <div class="addpanel" @click="dialogVisible=true">
                                <div>
                                    <span>新建项目</span>
                                    <span>+</span>
                                </div>
                            </div>
                    </el-col>
                    <el-col 
                        :xs="24" 
                        :md="12" 
                        :lg="8" 
                        :xl="6"
                        v-for="(item,index) in proList" :key="index">
                        <div class="post-infos" >
                            <div @click="view(item)">
                                <div>
                                    <h5 class="text-overflow m-t-0" :title="item.name">{{item.name}}</h5>
                                </div>
                                <p class="text-muted m-b-0 project-js">项目类型：{{item.type==1?'创意':'初创/成长'}}</p>
                            </div>
                            <div class="data_box clearfix">
                                <span class="fl">
                                    {{item.createTime}}
                                </span>
                                <span class="fr" v-show="istrue==1">
                                    <i class="el-icon-delete" @click="deletes(item)"></i>
                                </span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="elcol_divs" v-show="istrue==2&&proList.length==0">
                <div class="default-wrappers" >
                    <img src="./../../assets/img/bookmark.svg" alt="">
                    <p><span>未参与任何项目</span></p>
                </div>
            </div>
        </div>
        <div class="joinclass">
            <el-dialog
                title="新建项目"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="600px"
                :before-close="handleClose">
                    <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
                        <el-form-item label="项目名称：" prop="name">
                            <el-input v-model.number="ruleForm.name" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                        <el-form-item label="项目类型：" prop="type">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio :label="1">创意</el-radio>
                                <el-radio :label="2">初创/成长</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="closei('ruleForm')">取 消</el-button>
                        <el-button type="primary" @click="addPro('ruleForm')">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            istrue:1,
            dialogVisible:false,
            ruleForm:{
                name:'',
                type:1
            },
            rules:{
                name:[
                    { required: true, message: '请输入项目名称', trigger:'blur'},
                ],
                type:[
                    { required: true, message: '请选择项目类型', trigger:'blur'},
                ],
            },
            proList:[],
            form:{},
        }
    },
    methods: {
        getapp(){
            this.axios.projectmy({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data.course
                    this.proList = res.data.data.create
                }else{
                    this.proList = []
                }
            }).catch(err=>{

            })
        },
        // 新增项目
        addPro(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.projectcreate({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        name:this.ruleForm.name,
                        type:this.ruleForm.type
                    }).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('项目创建成功'),
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        istrues(val){
            this.axios.projectmy({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data.course
                    if(val==1){
                        this.proList = res.data.data.create
                    }else{
                        this.proList = res.data.data.join
                    }
                    this.istrue = val
                }else{
                    this.proList = []
                }
            }).catch(err=>{

            })
        },
        //查看项目详情
        view(item){
            this.$router.push({name:'Createaproject',query:{istrue:this.istrue}})
            this.$store.dispatch("pro_id",item.id)
        },
        // 删除项目
        deletes(val){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.projectcdel({
                    token:this.$store.state.token,
                    id:val.id
                }).then(res=>{
                    if(res.data.code==0){
                        this.$message.success('删除成功')
                        this.getapp()
                    }else{
                        this.$message.info(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //返回上一层
        returnTo(){
            this.$router.push({name:'Myclass'})
        },
        handleClose(done){
            
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Projectcreation.css';
</style>
<style>
.joinclass .el-dialog__body{
    padding: 30px 40px;
}
.joinclass .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.joinclass .el-radio-group{
    width: 100%;
}
@media only screen and (min-width: 990px){
    .card-box .el-col-md-4{
        display: inline-block;
    }
    .card-box .el-col-md-5{
        display: inline-block;
    }
}

</style>